import "./App.css";
import * as React from "react";
import {
  Box,
  Center,
  ChakraProvider,
  color,
  DarkMode,
  RadioGroup,
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub } from "react-icons/fa";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  ExternalLinkIcon,
  Image,
  useToast,
  Button,
  extendTheme,
  Switch,
  colorMode,
  Divider,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";

import { AspectRatio } from "@chakra-ui/react";

//import { ExternalLinkIcon } from "@chakra-ui/icons";

//import { ExternalLinkIcon } from "@chakra-ui/icons";

import m_tabs from "./components/primarytabs";
import resume from "./resume.png";
import m_pic from "./headshot.png";
import lowpass from "./lowpass.png";
import cone_protect from "./cone_protect.png";
import mario from "./mario.png";
import lug from "./lug analysis.png";
import cad from "./cad.png";
import dg1 from "./dg1.png";

import blimp from "./blimp.png";
import d1 from "./d1.png";
import d2 from "./d2.png";
import d3 from "./d3.png";
import d4 from "./d4.png";
import d5 from "./d5.png";
import d6 from "./d6.png";
import sail from "./sail1.jpg";
import sail1 from "./sail2.jpg";
import sail2 from "./sail3.jpg";
import sailf from "./sailf.png";
import ocean from "./ocean.png";
import ocean1 from "./ocean2.png";
import ocean2 from "./ocean3.png";
import grand from "./grand.png";
import proj from "./proj.png";

const customTheme = extendTheme({
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "#d4f3e7",
        },
      },
    },
  },
});

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};
const theme = extendTheme({ config });

function App() {
  return (
    <ChakraProvider color-scheme="red">
      <Tabs bg={"white"} isFitted variant="enclosed">
        <TabList fontFamily={"Rubik"} mb="1em">
          <Tab _selected={{ color: "white", bg: "#0070A4" }}>Home</Tab>

          <Tab _selected={{ color: "white", bg: "#0070A4" }}>Projects</Tab>
          <Tab _selected={{ color: "white", bg: "#0070A4" }}>Resume</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div>
              <Box
                bg={"#0070A4"}
                w="100%"
                p={30}
                overflow={"hidden"}
                float={"none"}
              >
                <Image src={grand} alt="Picture of Ian" class="column2" />
              </Box>

              <p class="about">About</p>

              <p class="sum">
                Hi, I'm Ian!<br></br> I currently study electrical engineering
                at UCLA with a specialization in Computer Science. I am
                passionate about all things electric, with an interest in
                software and mechanical engineering. To showcase some of my
                projects and other hobbies, I created this website in React JSX.
              </p>
              <p class="sum">
                <br></br>For the past year, I have been working with the Bruin
                Formula SAE Team to assist with the building of the team’s
                high-performance Racing Kart, MARK 8. Currently, I am a member
                of the Powertrain Subteam where I work with another Bruin
                teammate to design and manufacture the engine mounting system
                for the next iteration of our race car. Most recently, I
                performed an air force lug analysis to ensure our new engine
                mounting design is optimal.
              </p>
              <p class="sum">
                <br></br> I love all things computer science and plan on taking
                several machine learning courses prior to graduation. This past
                summer, I had the opportunity to learn some front-end
                development through React JSX. At the same time, I learned
                lower-level assembly language code and C in one of my favorite
                UCLA courses, CS33.
              </p>
              <p class="sum">
                <br></br>
                Having grown up in Newport Beach, I am a lifelong sailor and
                admirer of our blue ocean. Some of my other hobbies include
                skiing, surfing, chess, and rock climbing.
              </p>
              <p class="sum">
                <br></br>
                If you would like to contact me or connect on linkedin, please
                visit the links at the bottom of this page. Otherwise, navigate
                the tabs at the top to view some of my projects and resume.
              </p>
              <p class="about">Hobbies and Interests</p>
              <Image src={proj} alt="Picture of Art" class="art" />
              <p class="sum6">
                <br></br>
                The images above are screenshots of some of my recent projects.
                If you would like to learn more, scroll to the top of the page
                and explore the projects tab. Featured in these snapshots are
                the engine mounting CAD, a homemade Spy Microphone, and a C++
                object oriented video game.
              </p>
              <Image src={sailf} alt="Picture of Art" class="art" />

              <p class="sum6">
                <br></br>I was fortunate to compete in the 2022 Rose Cup, the US
                Invitational Youth Match Racing Championship in Long Beach, CA.
                The pictures above are of my team and me sailing a 27' Soling
                during the race. We made it to the Semifinals and we were
                unanimously awarded the 2022 Nick Scandonne
                Sportsmanship/Leadership Trophy by the other teams.
              </p>

              <Image src={dg1} alt="Picture of Art" class="art" />

              <p class="sum6">
                <br></br>
                <Link href="https://openai.com/dall-e-2/" color="teal.500">
                  DALLE 2.0
                </Link>{" "}
                is an open AI that allows users to create realistic images from
                text descriptions. Using machine learning and complex neural
                networks, DALL-E 2.0 cross-examines images on the internet to
                produce unique, never before seen artwork. This innovative feat
                of engineering inspires me to further my own understanding of
                software. If you would like to learn more about DALL-E 2.0 or
                are interested in making art yourself, click the link here!{" "}
                <Link href="https://openai.com/dall-e-2/" color="teal.500">
                  DALLE 2.0
                </Link>
              </p>

              <Box
                bg={"#0070A4"}
                w="100%"
                p={2}
                margin={"auto"}
                overflow={"hidden"}
                float={"none"}
              >
                <p class="contact">
                  Contact<br></br>ianchristopherwells@gmail.com
                </p>
                <p class="linker">
                  <Button
                    w="100px"
                    fontSize={"50%"}
                    colorScheme="linkedin"
                    rightIcon={<FaLinkedin />}
                    margin={"10px"}
                  >
                    <LinkOverlay href="https://www.linkedin.com/in/ianchristopherwells/">
                      Linkedin
                    </LinkOverlay>
                  </Button>
                  <Button
                    w="100px"
                    fontSize={"50%"}
                    backgroundColor={"black"}
                    color={"white"}
                    rightIcon={<FaGithub />}
                    z
                    margin={"10px"}
                  >
                    <LinkOverlay href="https://github.com/IanCWells">
                      GitHub
                    </LinkOverlay>
                  </Button>
                </p>
              </Box>

              <Divider class="div" />
            </div>
          </TabPanel>
          <TabPanel>
            <p class="about2">Project Portfolio</p>
            <p class="sum">Mk7 and Mk8 Bruin Racing Engine Mounting 2022</p>

            <Image
              src={cad}
              alt="Picture of 2D Platformer"
              class="luganalysis"
            />
            <p class="sum2">
              Worked with a fellow Bruin club member to optimally design a
              system to support the engine for the next iteration of the Bruin
              formula racing cart (Mk8). Performed air force lug analysis to
              ensure efficient safety factors. Manufacturing and testing to
              continue Fall & Winter of 2022. Also worked with 2022 Spring team
              to manufacture tabs, bushings, and place engine on the Mk7 cart.
              SolidWorks, Lathe, Waterjet.
            </p>
            <Divider class="div" />
            <p class="sum3">Pathfinding Robot (Arduino) Spring 2022</p>

            <AspectRatio maxW="560px" ratio={1.5} margin={"auto"}>
              <iframe
                title="Pathfinding Robot"
                src="https://www.youtube.com/embed/0nNgpS6YJck"
                allowFullScreen
              />
            </AspectRatio>

            <p class="sum4">
              8 week project to design and build a functional pathfinding robot
              that successfully navigates a maze. Utilizes Arduino, Python, and
              understanding of circuitry. Concepts of PID controller.
            </p>
            <Divider class="div" />
            <p class="sum3">Spy Microphone Fall 2021</p>
            <Image src={lowpass} alt="Picture of Low Pass" class="center2" />
            <p class="sum2">
              Led a team of engineers to design a functional “Spy-Microphone”
              that successfully amplifies volume and eliminates unwanted sound
              frequencies using both amplification and high pass filter
              circuitry. Project uses Arduino software.
            </p>
            <Divider class="div" />
            <p class="sum3">
              UCLA Bruin SAE Formula Racing Cone Protection System Winter 2022
            </p>
            <Image
              src={cone_protect}
              alt="Picture of Cone Protection System"
              class="center2"
            />
            <p class="sum2">
              Used Solid Works to design a radiator protection system that
              mounts to the chassis of the Bruin Formula racing kart.
              Lightweight winged design minimizes change in airflow and prevents
              damage to the radiator. Led a sub team of engineers to manufacture
              parts.
            </p>
            <Divider class="div" />
            <p class="sum3">2D Platformer Video Game (C++) Winter 2022</p>
            <Image src={mario} alt="Picture of 2D Platformer" class="center3" />
            <p class="sum2">
              40+ hr project using C++. Final product is a multiple level 2D
              platformer Mario-like video game. Object oriented programming,
              class hierarchy, polymorphism, and inheritance all used. Learned
              to adequately organize and design coding structure.
            </p>
            <Divider class="div" />

            <p class="sum3">Matchmaker (C++) Winter 2022</p>
            <p class="sum2">
              30+ hr project using C++. Matchmaker is a Big-O efficient search
              engine that matches people based on attributes and preferences.
              Uses standard library data structures to search optimally for
              matches across 100,000+ individuals.
            </p>
            <Divider class="div" />
            <p class="sum3">Malloc Dynamic Memory Allocator (C) Summer 2022</p>
            <p class="sum2">
              Developed a functional low-level Malloc memory allocator using C.
              Implementation uses an explicit doubly linked list to track
              segments of free memory within the heap. Utilizes GDB, VIM, and
              understanding of lower-level assembly language.
            </p>
            <Divider class="div" />

            <Box
              bg={"#0070A4"}
              w="100%"
              p={2}
              margin={"auto"}
              overflow={"hidden"}
              float={"none"}
            >
              <p class="contact">
                Contact<br></br>ianchristopherwells@gmail.com
              </p>
              <p class="linker">
                <Button
                  w="100px"
                  fontSize={"50%"}
                  colorScheme="linkedin"
                  rightIcon={<FaLinkedin />}
                  margin={"10px"}
                >
                  <LinkOverlay href="https://www.linkedin.com/in/ianchristopherwells/">
                    Linkedin
                  </LinkOverlay>
                </Button>
                <Button
                  w="100px"
                  fontSize={"50%"}
                  backgroundColor={"black"}
                  color={"white"}
                  rightIcon={<FaGithub />}
                  z
                  margin={"10px"}
                >
                  <LinkOverlay href="https://github.com/IanCWells">
                    GitHub
                  </LinkOverlay>
                </Button>
              </p>
            </Box>
          </TabPanel>

          <TabPanel>
            <img src={resume} class="resume" />
            <p></p>
            <Box
              bg={"#0070A4"}
              w="100%"
              p={2}
              margin={"auto"}
              overflow={"hidden"}
              float={"none"}
            >
              <p class="contact">
                Contact<br></br>ianchristopherwells@gmail.com
              </p>
              <p class="linker">
                <Button
                  w="100px"
                  fontSize={"50%"}
                  colorScheme="linkedin"
                  rightIcon={<FaLinkedin />}
                  margin={"10px"}
                >
                  <LinkOverlay href="https://www.linkedin.com/in/ianchristopherwells/">
                    Linkedin
                  </LinkOverlay>
                </Button>
                <Button
                  w="100px"
                  fontSize={"50%"}
                  backgroundColor={"black"}
                  color={"white"}
                  rightIcon={<FaGithub />}
                  z
                  margin={"10px"}
                >
                  <LinkOverlay href="https://github.com/IanCWells">
                    GitHub
                  </LinkOverlay>
                </Button>
              </p>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}

export default App;
